import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { LoginAppsGuardService } from './login/guards/login-apps-guard.service';

const routes: Routes = [
  {
    path: 'sso-login-popup',
    loadComponent: () => import('./login/screens/sso-login-popup/sso-login-popup.component').then(m => m.SsoLoginPopupComponent)
  },
  {
    path: 'sso-login-popup-check-cookie-close-window',
    loadComponent: () => import('./login/screens/sso-login-popup-check-cookie-close-window/sso-login-popup-check-cookie-close-window.component').then(m => m.SsoLoginPopupCheckCookieCloseWindowComponent),
    canActivate: [LoginAppsGuardService]
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  exports: [
    RouterModule
  ],
  /**
   * This app is not using the hash in the URL
   * **/
  imports: [
    RouterModule.forRoot(routes, {useHash: false, preloadingStrategy: PreloadAllModules})
  ]
})
export class AppRoutingModule {
}
