/**
 * PR environment config
 */

const website_domain = "https://www-staging.zoominfo.com";
const base_login_canonical = "https://login-prs.zoominfo.com";
const cookies_domain = ".zoominfo.com";
const app_domain = "https://login-prs.zoominfo.com";
const lb_domain = website_domain;

export const environment = {
  // prs specific configuration
  production: false,
  name: "prs",
  app_domain,
  website_domain,
  base_login_canonical,
  cookies_domain,
  bs_regular_domain: "https://dozi-staging.zoominfo.com/bizservices",
  lb_domain,
  login_backend_domain: "https://login-staging.zoominfo.com/api",
  grow_backend_domain: "https://dozi-staging.zoominfo.com/anura",
  amplitude_api_key: "6df4d4fa782dba3adb6191a1b24e4b4f",

  ums_password_reset_url: "https://login-prs.zoominfo.com/gw/ziapi/ums/v2/passwordReset",  // NOSONAR
  sso_redirect_url: "https://gw-app-staging.zoominfo.com/okta/ssoRedirect",
  //
  dozi_url_enterprise: "https://dozi-staging.zoominfo.com",
  dozi_url_community: "https://dozi-staging.zoominfo.com",
  dozi_url_reachout: "https://dozi-staging.zoominfo.com/#/reachout/routing",
  dozi_regression_url: "https://dozi-regression.zoominfo.com",
  dozi_lite_url: "https://apps-dev.zoominfo.com/zi-lite/#/",
  dozi_lite_new_url: "https://zi-lite-dev.zoominfo.com/#/",
  dozi_lite_regression_url: "https://apps-regression.zoominfo.com/zi-lite/",
  dozi_lite_new_regression_url: "https://zi-lite-regression.zoominfo.com/",
  //
  zi_url_enterprise: "https://grow-staging.zoominfo.com",
  zi_url_community: "https://grow-staging.zoominfo.com",
  //
  recruiter_url_enterprise: "https://recruiter-app-staging.zoominfo.com",
  recruiter_url_community: "https://recruiter-app-staging.zoominfo.com",
  recruiter_url_reachout:
    "https://recruiter-app-staging.zoominfo.com/reachout/routing",
  //
  marketing_cloud_url_enterprise: "https://marketingos-staging.zoominfo.com",
  //
  engage_url_enterprise: "https://engage-app-staging.zoominfo.com",
  //
  admin_url_enterprise: "https://admin-dev.zoominfo.com/",
  admin_regression_url: "https://admin-regression.zoominfo.com/",
  //
  tam_url_enterprise: "https://tam-staging.zoominfo.com/",
  //
  operations_url_enterprise: "https://operationsos-stg.zoominfo.com/",
  zi_backoffice_url:
    "https://backoffice-staging.zoominfo.com/backoffice/home/index",
  //
  microsoft_clientId: "29f78c74-171c-44fa-a79f-9c6865c7464f",
  google_clientId:
    "847459799279-6ij2vd0cr1t9atvc4833fjg3171s2bbo.apps.googleusercontent.com",
  GOOGLE_TAG_MANAGER_ID: "GTM-MQM5HKH",
  //okta login configs
  googleIdpId: "0oa23qz2lscj0XW881d7",
  microsoftIdpId: "0oa24cbjtbzQCaIPu1d7",
  sfIdpId: "0oa8gocn2oE3jYMnM1d7",
  oktaClientId: "0oa224kozwgZTpJCM1d7",
  oktaLoginUrl: "https://okta-login-staging.zoominfo.com",
  oktaIssuer: "https://okta-login-staging.zoominfo.com/oauth2/default",
  oktaLegacyIssuer: 'https://zoominfo-ciam-staging.oktapreview.com/oauth2/default',
  sm_ping_url: "https://login-staging.zoominfo.com/gw/ziapi/okta/ping",
  sm_refresh_storage_url: "https://login-staging.zoominfo.com/gw/ziapi/refreshToken",
  sm_refresh_access_token_url: "https://login-staging.zoominfo.com/gw/ziapi/refreshAccessToken",
  sm_auto_provisioning_login_url: "https://gw-app-staging.zoominfo.com/gw/ziapi/autoProvisioningLogin",
  oktaDeleteSessionUrl: "https://okta-login-staging.zoominfo.com/api/v1/users/me/lifecycle/delete_sessions",
  oktaGetSessionsMeUrl: "https://okta-login-staging.zoominfo.com/api/v1/sessions/me",

  skilljarUrlPartial: 'zoominfo-ciam-staging_skilljarsaml_1',
  // ZI-Lite sign up URL
  signup_url: 'https://signup-regression.zoominfo.com/#/',
  settings_url: 'https://dozi-staging.zoominfo.com/ziapi/settings/api',
  play_store_url: 'https://play.google.com/store/apps/details?id=com.zoominfo.enterprise.staging&hl=en',
};
