import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RecaptchaModule} from 'ng-recaptcha';
import { MatDialogModule } from '@angular/material/dialog';
import {CommonService} from "./common.service";
import { AnalyticsService } from '../core/services/analytics.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestsService } from '../login/service/requests.service';


@NgModule({
    imports: [
        CommonModule,
        RecaptchaModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        CommonService,
        AnalyticsService,
        RequestsService
    ]
})
export class ZiCommonModule {
}
