import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { NavigationStart, Router, RouterOutlet } from "@angular/router";
import * as querystring from 'querystring-es3';
import { NotyComponent } from './common/components/noty/noty.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterOutlet, NotyComponent]
})
export class AppComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.activateGoogleTagManager();
    // Back-Support for removing hash from URL
    console.log(`Before - ${window.location.href}`);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.startsWith('/#/')) {
          const redirectTo = event.url.split('?')[0].slice(2);
          const questionMarkIndex = window.location.href.indexOf('?');
          const queryObject = { queryParams: {} };
          //
          if (questionMarkIndex > 0) {
            const urlParams = window.location.href.slice(questionMarkIndex + 1);
            if (urlParams) {
              queryObject.queryParams = querystring.parse(urlParams);
            }
          }
          console.log(`After - ${redirectTo}, queryObject: ${JSON.stringify(queryObject)}`);
          this.router.navigate([redirectTo], queryObject);
        }
      }
    });
  }

  activateGoogleTagManager() {
    ((w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0];
      var j: HTMLScriptElement = d.createElement('script');
      var dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', environment.GOOGLE_TAG_MANAGER_ID);  }
}
