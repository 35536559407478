import {Injectable} from '@angular/core';

import type { ZoominfoAnalytic } from '@zoominfo/zoominfo-analytic';
import {environment} from '../../../environments/environment';
import * as _ from "lodash-es";
import {LoginCookieService} from "./login-cookie.service";
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import adaEmbed from "@ada-support/embed2";
import { CookieCategories } from '../../utility/cookie-categories';
import { CookiePrefixes } from '../../utility/cookie-prefixes';
import { LoginConfig } from '../../login/login.config';

export enum LoginPageType {
  MAIN = 'Main',
  MAIN_REACHOUT = 'Main Reachout',
  SFNA = 'SFNA',
  MDNA = 'MDNA',
  WEBVIEW = 'Webview',
}

export enum LoginType {
  CREDENTIALS = 'Credentials',
  GOOGLE_SOCIAL_AUTH = 'Google Social Auth',
  MS_SOCIAL_AUTH = 'Microsoft Social Auth',
  SALESFORCE_SOCIAL_AUTH = 'Salesforce Social Auth',
  SSO = 'SSO',
  WEBVIEW = 'Webview',
}

export declare var uxtrack;

/**
 * Analytics Service
 */
@Injectable()
export class AnalyticsService {
  // use separate variables for page load and sign in so there is no conflict with the timers
  private pageLoadStartTime: number;
  private pageLoadEndTime: number;
  private loginFormLoadEndTime: number;
  private oktaWidgetLoadEndTime: number;
  private signInEndTime: number;  // time when Okta login finishes (ms)
  private sessionVerifyEndTime: number;  // time when session was verified after Okta login redirection (ms)
  private used2FA = false;  // set to true if 2fa was used
  private zoominfoAnalytic: ZoominfoAnalytic;
  private amplitudeInstance: any;
  private datadogLogs: any;
  private _amplitudeLoadedSource = new BehaviorSubject<boolean>(false);
  private _zoominfoAnalyticLoadedSource = new BehaviorSubject<boolean>(false);
  private _datadogLogsLoadedSource = new BehaviorSubject<boolean>(false);
  private _allCookiesAllowed = true;  // enable/disable using non-essential cookies
  private amplitudeLoaded$ = this._amplitudeLoadedSource.asObservable();
  private zoominfoAnalyticLoaded$ = this._zoominfoAnalyticLoadedSource.asObservable();
  private datadogLoggerLoaded$ = this._datadogLogsLoadedSource.asObservable();

  readonly PAGE_LOAD_THRESHOLD = 60000; // 1 min

  constructor(private loginCookieService: LoginCookieService) {
    if (window.performance) {
      // window.performance.timing is deprecated, use window.performance.timeOrigin instead
      this.pageLoadStartTime = window.performance.timeOrigin;  // close to window.performance.timing.navigationStart
    }
    const env: string = environment.name;
    import ('@amplitude/analytics-browser').then((Amplitude) => {
      this.amplitudeInstance = Amplitude.createInstance();
      this.amplitudeInstance.init(environment.amplitude_api_key, {
        defaultTracking: false,
        identityStorage: this._allCookiesAllowed ? 'cookies' : 'localStorage'
      });
      console.log('Resetting Amplitude user...');
      this.amplitudeInstance.reset();  // anonymize user
      this._amplitudeLoadedSource.next(true);
      this._amplitudeLoadedSource.complete();
    });
    import('@zoominfo/zoominfo-analytic').then((ZoominfoAnalytic) => {
      this.zoominfoAnalytic = ZoominfoAnalytic;
      this._zoominfoAnalyticLoadedSource.next(true);
      this._zoominfoAnalyticLoadedSource.complete();
    });
    import('@datadog/browser-logs').then((datadogLogs) => {
      this.datadogLogs = datadogLogs.datadogLogs;
      this.datadogLogs.init({
        clientToken: 'pub141b561e8db9405ecbedfb77d0869f3d',
        site: 'datadoghq.com',
        service: 'login-UI',
        env: env,
        forwardErrorsToLogs: false,
        sampleRate: this.getSampleRate(env),
        trackingConsent: this._allCookiesAllowed ? 'granted' : 'not-granted'
      });
      this._datadogLogsLoadedSource.next(true);
      this._datadogLogsLoadedSource.complete();
    });

    this.listenForConsentChange();
  }

  getSampleRate(env: string): number {
    return env === 'production' ? 1 : 100;
  }

  /**
   * Fires an analytics event to the analytics services.
   * @param eventName {String} The name of the event to fire
   * @param eventProperties {Object} A set of properties that are related to  to this event.
   * @example
   * send('OpenedTool', {
   *      source: 'Login',
   *      tool: 'TargetAccounts'
   *    });
   */
  public sendAmplitudeEvent(eventName: string, eventProperties: object): void {
    try {
      const event = this.zoominfoAnalytic.events.make(eventName, eventProperties);

      console.log('Sending Analytics event:', eventName, eventProperties);
      this.amplitudeInstance.logEvent(event.name, event.properties);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * This method is responsible to send GA events
   * **/
  public sendGAEvent(event) {
    if (this._allCookiesAllowed) {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push(event);
    }
  }

  /**
   * This method is responsible to set the user's zoom ID on the analytics providers
   * **/
  public setUserId({name = '', id = '', email = ''}) {
    console.log('Setting Amplitude user id...');
    this.amplitudeInstance.setUserId(id);
  }

  /**
   * This method sends a custom event to DD
   * **/
  public sendDataDogEvent(eventName, eventProperties) {
    if (!window || !window['DD_RUM']) {
      return;
    }
    window['DD_RUM'].addAction(eventName, eventProperties);
  }

  // sends log to DD
  public logDataDogEvent(eventName, eventProperties) {
    this.datadogLogs.logger.info(eventName, eventProperties);
  }

  public initAda(userDetails) {
    const email = _.get(userDetails, 'email', null) || this.loginCookieService.getCookie('userEmail') || null;
    const metaFields = _.omitBy({
      username: email,
      phone_number: _.get(userDetails, 'phone', null),
      email,
      name: _.get(userDetails, 'name', null)
    }, _.isNil);

    adaEmbed.start({
      handle: "zoominfo",
      metaFields,
      adaReadyCallback: () => {

        const adaMainElement = document.getElementById('ada-button-frame');
        if (adaMainElement) {
          adaMainElement.style.display = 'none';
        }

        // const isLziLoginVersion = this.commonService.isLziLoginVersion();
        // if (!isLziLoginVersion) {
        //   setAdaChatBotToLeft();
        // }
        //
        // function setAdaChatBotToLeft() {
        //   const elementsIdsToSetStyles = ['ada-button-frame', 'ada-intro-frame', 'ada-x-storage-frame'];
        //   elementsIdsToSetStyles.forEach(elementId => {
        //     const element = document.getElementById(elementId);
        //     if (element) {
        //       element.classList.add('ada-float-left');
        //     }
        //   });
        // }
      }
    });
  }

  startPageLoadTimer() {
    this.pageLoadStartTime = new Date().getTime();
  }

  endPageLoadTimer() {
    this.pageLoadEndTime = new Date().getTime();
  }

  /** Call this LoginFormComponent finishes loading. */
  endLoginFormLoadTimer() {
    this.loginFormLoadEndTime = new Date().getTime();
  }

  /** Call this when Okta Widget finishes loading. */
  endOktaWidgetLoadTimer() {
    this.oktaWidgetLoadEndTime = new Date().getTime();
  }

  getPageLoadStartTime(): number {
    return this.pageLoadStartTime;
  }

  getPageLoadEndTime(): number {
    return this.pageLoadEndTime;
  }

  getPageLoadTime(): number {
    return this.pageLoadEndTime - this.pageLoadStartTime;
  }

  getOktaWidgetLoadTime(): number {
    return this.oktaWidgetLoadEndTime - this.pageLoadStartTime;
  }

  /** Get the load time of LoginFormComponent. */
  getLoginFormLoadTime(): number {
    return this.loginFormLoadEndTime - this.pageLoadStartTime;
  }

  /** Send LoginPageLoadTime DD log.
   *  TTI: time to interactive (time it takes for the login form to be interactive)
   *  pageLoadTime: time it takes for the page to load (excluding Okta Widget and ADA chat bubble)
   *  oktaWidgetLoadTime: time it takes for the Okta Widget to load
   */
  sendLoginPageLoadTimeLog(isReachout: boolean, isInWebView: boolean) {
    const loginFormComponentLoadTime = this.getLoginFormLoadTime();
    const oktaWidgetLoadTime: number = this.getOktaWidgetLoadTime();
    const pageLoadTime = this.getPageLoadTime();
    let type: LoginPageType;
    let eventProperties: {}

    if (isReachout) {
      type = LoginPageType.MAIN_REACHOUT;
    } else {
      type = isInWebView ? LoginPageType.WEBVIEW : LoginPageType.MAIN;
    }
    eventProperties = { type, tti: loginFormComponentLoadTime, pageLoadTime, oktaWidgetLoadTime };

    this.printPageLoadTime(type, loginFormComponentLoadTime, pageLoadTime, oktaWidgetLoadTime);
    this.setLoginPageType(type);
    // send logs to DD if page load time is valid
    if (this.validPageLoadTime(type, loginFormComponentLoadTime, pageLoadTime, oktaWidgetLoadTime)) {
      this.logDataDogEvent('LoginPageLoadTime', eventProperties);
    }
  }

  /*
  ** Load times should be greater than 0 ms and page type should exist.
  ** Not a valid time if loading is unrealistically long, this metric should not be affected by outside factors
  ** (e.g. internet connection issue, browser frozen for a time, etc.)
  ** Slow internet: valid, but 1 minute is still unrealistic
  ** Browser frozen: invalid, it could freeze for a long time
  */
  validPageLoadTime(type: LoginPageType, tti: number, pageLoadTime: number, oktaWidgetLoadTime: number) {
    return type && tti > 0 && tti < this.PAGE_LOAD_THRESHOLD &&
      oktaWidgetLoadTime > 0 && oktaWidgetLoadTime < this.PAGE_LOAD_THRESHOLD &&
      pageLoadTime > 0 && pageLoadTime < this.PAGE_LOAD_THRESHOLD;
  }

  private printPageLoadTime(type: LoginPageType, tti: number, pageLoadTime: number, oktaWidgetLoadTime: number) {
    console.log(`login page type: ${type}`);
    console.log(`TTI (time to interactive): ${tti} ms`);
    console.log(`pageLoadTime (excluding Okta Widget and ADA chat bubble): ${pageLoadTime} ms`);
    console.log(`oktaWidgetLoadTime: ${oktaWidgetLoadTime} ms`);
  }

  startSignInTimer() {
    localStorage.setItem('signInStartTime', String(new Date().getTime()));
  }

  getStartSignInTime(): number {
    return Number(localStorage.getItem('signInStartTime'));
  }

  endSignInTimer() {
    this.signInEndTime = new Date().getTime();
  }

  endSessionVerifyTimer() {
    this.sessionVerifyEndTime = new Date().getTime();
  }

  // get login time in milliseconds
  getSignInTime(): number {
    return this.signInEndTime - this.getStartSignInTime();
  }

  // get the time it took to verify session including login time in milliseconds
  getSessionVerifyTime(): number {
    return this.sessionVerifyEndTime - this.getStartSignInTime();
  }

  setLoginType(type: LoginType) {
    localStorage.setItem('loginType', type);
  }

  getLoginType(): LoginType {
    return this.getLoginTypeFromStr(localStorage.getItem('loginType'));
  }

  // convert login type name to LoginType enum
  getLoginTypeFromStr(typeStr: string): LoginType {
    // default login type: credentials
    return Object.values(LoginType).some((val: string) => val === typeStr) ? typeStr as LoginType : LoginType.CREDENTIALS;
  }

  // convert login type name to LoginType enum
  getLoginPageTypeFromStr(typeStr: string): LoginPageType {
    // default page: main
    return Object.values(LoginPageType).some((val: string) => val === typeStr) ? typeStr as LoginPageType : LoginPageType.MAIN;
  }

  setLoginPageType(loginPageType: LoginPageType) {
    localStorage.setItem('loginPageType', loginPageType);
  }

  getLoginPageType(): LoginPageType {
    return this.getLoginPageTypeFromStr(localStorage.getItem('loginPageType'));
  }

  // determine if 2fa was used
  setUsed2FA(oktaTokenStorage) {
    this.used2FA = oktaTokenStorage ? oktaTokenStorage.idToken.claims.used2FA : false;
  }

  getUsed2FA(): boolean {
    return this.used2FA;
  }

  handleAppLauncherLoaded() {
    try {
      uxtrack.end({
        action : 'SignInToAppLauncher'
      });
    } catch (err) {
      console.log(err);
    }
  }

  sendLoginTimeToDD() {
    let loginType: LoginType = this.getLoginType();
    let loginPageType: LoginPageType = this.getLoginPageType();
    const used2FA: boolean = this.getUsed2FA();
    const loginTime: number = this.getSignInTime();
    const sessionVerifyTime = this.getSessionVerifyTime();
    console.log(`loginTime: ${loginTime} ms`);
    try {
      uxtrack.end({
        action : 'LoginTime',
        loginPageType,
        loginType
      });
    } catch (err) {
      console.log(err);
    }

    // only send logs if we got the time
    if ((loginTime && loginTime > 0)) {
      if (loginType === LoginType.CREDENTIALS) {
        this.logDataDogEvent('LoginTime', { loginPageType, loginType, loginTime, sessionVerifyTime, used2FA });
      } else {
        this.logDataDogEvent('LoginTime', { loginPageType, loginType, loginTime, sessionVerifyTime });
      }
    }

    localStorage.removeItem('loginType');
    localStorage.removeItem('loginPageType');
    localStorage.removeItem('signInStartTime');
  }

  startTrackingLoginTime() {
    const loginPageType: LoginPageType = this.getLoginPageType();
    const loginType: LoginType = this.getLoginType();

    try {
      uxtrack.start({
        action : 'LoginTime',
        loginPageType,
        loginType,
        used2FA : false
      });
      uxtrack.start({
        action : 'SignInToAppLauncher',
        loginPageType,
        loginType,
        used2FA : false
      });
    } catch (err) {
      console.log(err);
    }
  }

  trackAppLoadStartTime() {
    try {
      uxtrack.start({
        action : 'AppLoadTime'
      });
    } catch (err) {
      console.log(err);
    }
  }

  startLoginTracking(loginType: LoginType) {
    this.startSignInTimer();
    this.setLoginType(loginType);
    this.startTrackingLoginTime();
    this.trackAppLoadStartTime();
  }

  updateLogger2faFlag(used2FA: boolean) {
    try {
      uxtrack.update({
        action : 'LoginTime',
        used2FA : used2FA
      });
      uxtrack.update({
        action : 'SignInToAppLauncher',
        used2FA : used2FA
      });
      uxtrack.update({
        action : 'AppLoadTime',
        used2FA : used2FA
      });
    } catch (err) {
      console.log(err);
    }
  }

  updateLogPlatformInfo(isAppLauncher: boolean, platform?: string) {
    try {
      uxtrack.update({
        action : 'AppLoadTime',
        app : platform,
        isAppLauncher
      });
    } catch (err) {
      console.log(err);
    }
  }

  // return the source or origin value of login page to send to amplitude
  getLoginSource(isReachout: boolean): string {
    return isReachout ?'ChromeExtensionLogin' : 'WebLogin';
  }

  /* istanbul ignore next */
  /** Listen for OneTrust consent change when ready. */
  listenForConsentChange(): void {
    // do not reload for initial check since it happens automatically
    this.handleConsentConfirmation();  // check current consent
    const id = window.setInterval(() => {
      if (!!window['OneTrust'] && !!window['OneTrust'].OnConsentChanged) {
        const allowAllBtn = document.getElementById('onetrust-accept-btn-handler') as HTMLButtonElement;
        const acceptRecommendedBtn = document.getElementById('accept-recommended-btn-handler') as HTMLButtonElement;
        const rejectBtn = document.querySelector('.ot-pc-refuse-all-handler') as HTMLButtonElement;
        const confirmBtn = document.querySelector('.save-preference-btn-handler.onetrust-close-btn-handler') as HTMLButtonElement;

        // allowAllBtn only loads for the initial consent. acceptRecommendedBtn, rejectBtn, and confirmBtn will always load.
        if (rejectBtn && confirmBtn && acceptRecommendedBtn) {
          window.clearInterval(id);
        }
        this.oneTrustBtnClickListener(allowAllBtn);
        this.oneTrustBtnClickListener(acceptRecommendedBtn);
        this.oneTrustBtnClickListener(rejectBtn);
        this.oneTrustBtnClickListener(confirmBtn);
      }
    }, 100);
  }

  /** Handle OneTrust consent confirmation. This is invoked even if there was no change. */
  handleConsentConfirmation(): void {
    const prevAllCookiesAllowed = localStorage.getItem(LoginConfig.ONETRUST_ALLOW_ALL_LOCAL_STORAGE) === 'true';
    this._allCookiesAllowed = this.isAllowAllCookies();
    localStorage.setItem(LoginConfig.ONETRUST_ALLOW_ALL_LOCAL_STORAGE, String(this._allCookiesAllowed));  // store consent to handle page reloads
    setTimeout(() => {
      if (!this._allCookiesAllowed) {
        this.loginCookieService.deleteCookiesByPrefix(CookiePrefixes.AMPLITUDE);  // amplitude with hostname as domain
        this.loginCookieService.deleteCookiesByPrefix(CookiePrefixes.AMPLITUDE, '/', environment.cookies_domain);  // amplitude
        this.loginCookieService.deleteCookiesByPrefix(CookiePrefixes.GOOGLE_ANALYTICS, '/', environment.cookies_domain);  // google analytics
        // _dd_s cookie cannot be deleted until reloading the page. Disable tracking until the next page visit.
        if (prevAllCookiesAllowed && this.datadogLogs) {
          this.datadogLogs.setTrackingConsent('not-granted');
        } else {
          this.loginCookieService.deleteCookiesByPrefix(CookiePrefixes.DATADOG);  // datadog with hostname as domain
        }
      }
    }, 500);  // delete cookies after delay to catch cookies that are set after the consent change
  }

  /** Listen for the click event if the button exists. */
  oneTrustBtnClickListener(btn: HTMLButtonElement) {
    if (btn) {
      btn.addEventListener('click', () => {
        this.handleConsentConfirmation();
        window.location.reload();  // reload page after consent confirmation
      });
    }
  }

  /** Check if the user allowed all cookies in the OneTrust banner. */
  isAllowAllCookies(): boolean {
    const optanonConsent = this.loginCookieService.getCookie(LoginConfig.ONETRUST_CONSENT_COOKIE);
    return optanonConsent && optanonConsent.includes(`${CookieCategories.PERFORMANCE}:1`) && optanonConsent.includes(`${CookieCategories.TARGETING}:1`);
  }

  /** Wait for Amplitude to be ready */
  amplitudeReadyCheck(): Observable<[boolean, boolean]> {
    return forkJoin([
      this.amplitudeLoaded$,
      this.zoominfoAnalyticLoaded$,
    ]);
  }

  /** Wait for Datadog logger to be ready. */
  datadogLogsReadyCheck(): Observable<boolean> {
    return this.datadogLoggerLoaded$;
  }
}
