import 'zone.js';

import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app/ngrx/app.reducres';
import { StoreModule } from '@ngrx/store';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ZiCommonModule } from './app/common/common.module';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { bootstrapApplication } from '@angular/platform-browser';
import { LoginAppsGuardService } from './app/login/guards/login-apps-guard.service';
import { LoginCookieService } from './app/core/services/login-cookie.service';
import { CookieService } from 'ngx-cookie-service';
import { UrlService } from './app/core/services/url.service';
import { NotyService } from './app/common/components/noty/noty.service';
import { ValidationService } from './app/core/services/validation/validation.service';
import { TagsInjectionService } from './app/core/services/tags-injection.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(AppRoutingModule, FormsModule, ReactiveFormsModule, ZiCommonModule, StoreModule.forRoot({ appState: appReducers }, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }), StoreDevtoolsModule.instrument({
            maxAge: 50,
            logOnly: environment.production // Restrict extension to log-only mode
        })),
        TagsInjectionService,
        ValidationService,
        NotyService,
        UrlService,
        CookieService,
        LoginCookieService,
        LoginAppsGuardService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
})
  .catch(err => console.log(err));
